@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Tilt+Neon&display=swap");

@font-face {
  font-family: "Eagle Book";
  src: url("./Resources/fonts/EagleBook.otf") format("truetype");
}

@font-face {
  font-family: "Eagle Bold";
  src: url("./Resources/fonts/EagleBold.otf") format("opentype");
}

@font-face {
  font-family: "Eagle Light";
  src: url("./Resources/fonts/EagleLight.otf") format("opentype");
}

/* Track */
::-webkit-scrollbar {
  width: 6px;
  /* Adjust the width as desired */
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  /* Scrollbar thumb color */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #979797;
  /* Change color on hover */
}

/* Track on hover */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Track background color */
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
  background: #ddd;
  /* Change track color on hover */
}

html {
  scroll-behavior: smooth;
  --loaderSize: 100px;
  --loaderThick: 3px;
  --loaderAnimation: 2s;
  --color1: #182e4d;
  --color2: #ef763f;
}

body {
  overflow-x: hidden;
}

/* Slider.css */
.slider-fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mt-1r {
  margin-top: 1rem;
}

.mb-3r {
  margin-bottom: 3rem;
}

.font-eagleB {
  font-family: "Eagle Book";
}

.font-tiltN {
  font-family: "Tilt Neon", sans-serif;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.nav-align {
  font-family: "Tilt Neon", sans-serif;
  /* justify-content: space-; */
  /* margin-right: 3rem; */
  font-size: 18px;
}

.nav-link {
  color: rgba(0, 0, 0, 0.8) !important;
}

.logo {
  width: 85%;
  /* margin: 0 1rem; */
}

.navbar {
  background-color: rgb(71, 71, 71) !important;
}

.footer-logo {
  width: 85%;
}

.contact-img{
  width: 40%;
    aspect-ratio: 1 / 1;
    border-radius: 12px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 1rem !important;
  color: white !important;
}

.a-align-center {
  display: flex !important;
  align-items: center;
}

.slider-container {
  width: 100vw;
  height: 600px;
  background-color: red;
}

.sectionTagMain {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 45px;
}

.sectionTag {
  padding: 16px 0;
}

.text-big {
  font-size: 30px;
}

.text-small {
  font-size: 18px;
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 80%;
  margin: auto;
  font-family: "Ubuntu", sans-serif;
  padding: 10px;
}

.imgFluid {
  border-radius: 12px;
  width: 100%;
}

.paras {
  padding-right: 5rem;
}

.paras-left {
  padding-left: 5rem;
}

.thumbnail>img {
  width: 350px;
}

.w-400px {
  width: 400px !important;
}

/* LOADER CSS */

.loader-container {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.loader {
  position: relative;
  display: inline-block;
  width: var(--loaderSize);
  height: var(--loaderSize);
  border-radius: 50%;
  border: var(--loaderThick) solid;
  border-color: var(--color1) var(--color1) transparent;
  box-sizing: border-box;
  animation: loaderRotation var(--loaderAnimation) linear infinite;
}

.loader::after {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--loaderThick) solid;
  border-color: transparent var(--color2) var(--color2);
  width: calc(var(--loaderSize) / 2);
  height: calc(var(--loaderSize) / 2);
  border-radius: 50%;
  transform-origin: center center;
  margin: auto;
  animation: loaderRotationBack calc(var(--loaderAnimation) / 2) linear infinite;
}

@keyframes loaderRotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loaderRotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/* SERVICES SECTION */

.services {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card {
  padding: 2rem;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  /* width: 70vw; */
  /* justify-content: space-evenly; */
}

.service-card-dark {
  padding: 2rem;
  background: #1e2142;
  box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.25);
  width: 70vw;
  /* justify-content: space-evenly; */
}

.img-w100 {
  max-width: 340px;
  width: 100%;
}

.orange-color-main {
  color: #f2915d;
  font-weight: 500;
}

.services-list-item {
  /* font-family: "Eagle Light" !important; */
  color: #1e2142;
  font-size: 20px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 159.523%;
  list-style: none;
  display: flex;
}

.services-list-item:before {
  font-family: "Eagle Light" !important;
  content: "•";
  font-size: 90%;
  /* or whatever */
  padding-right: 10px;
}

.services-text-container {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.light-text {
  color: #1e2142;
  font-family: "Eagle Light" !important;
  font-size: 24px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 47px;
  /* 195.833% */
}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

.main {
  height: 50vh;
}

.hover-effect1 {
  min-height: 20px;
  background: #f44336;
  text-align: center;
}

.hover-effect1 ul {
  padding-top: 1px;
}

.hover-effect1 ul li {
  display: inline-block;
}

a {
  text-decoration: none !important;
}

.hover-effect1 ul li a {
  /* text-decoration: none; */
  font-size: 30px;
  text-align: center;
  color: #fff;
  margin: 0px 10px;
  line-height: 50px;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #dc3545;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.hover-effect1 ul li:hover a {
  border-radius: 50%;
  background: #dc3545;
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  text-decoration: underline !important;
}

.heading-big {
  font-size: 44px;
}

.opacity-half {
  opacity: 0.5;
}

.w-auto-imp {
  width: auto !important;
}

.footer-icon {
  height: 24px;
  width: auto;
  filter: invert(1) brightness(1.5);
}

.page-info{
  width: 80%;
  max-width: 80%;
  display: flex;
  margin: auto;
  padding: 10px;
  padding-top: 1rem;
}

.contact-icon {
  height: 24px;
  width: auto;
  /* filter: invert(1) brightness(1.5); */
}

.page-footer {
  color: white;
  background-color: rgb(71, 71, 71);
}

.page-footer h6 {
  font-size: 15px;
}

.page-footer p {
  font-size: 1rem;
}

.page-footer a {
  color: white !important;
  font-size: 1rem;
}

.mt-4r {
  margin-top: 4rem;
}

.align-flex-start {
  align-items: flex-start !important;
}

.dropdown-menu {
  padding: 0 !important;
  border-radius: 12px !important;
}

.dropdown-item {
  border-radius: 12px !important;
  padding: 1rem !important;
}

/* .top-bg {
  content: "";
  position: relative;
  left: 0;
  width: 100vw;
  height: 150px;
  background-image: url("./Resources/top-bg.png");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
} */

.small {
  position: absolute;
  top: 45%;
  left: 15%;
  color: #bbbdc3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.links {
  text-align: left;
}

.links p {
  padding: 0 1rem;
}

.map {
  border: 0px;
  width: 100vw;
  height: 350px;
}

.details-container {
  width: 80%;
  margin: auto;
  padding: 0 3rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  background: white;
  transform: translateY(-10%);
}

.details-box {
  color: #7b7b7b;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  /* 168.75% */
}

.form {
  margin-top: 2rem;
}

.form input {
  width: 95%;
  border-radius: 4px;
  border: 2px solid #f4f4f4;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 2px 4px 0px rgba(171, 171, 171, 0.13);
  font-family: "Roboto";
  color: #6c757d;
  font-size: 16px;
}

.text-align-left{
  text-align: left;
}

.first-input {
  padding: 1rem;
}

.other-inputs {
  padding: 1rem;
}

.form input:focus {
  outline: none;
}

.form textarea {
  width: 95%;
  resize: none;
  outline: none;
  border: none;
  border-radius: 4px;
  border: 2px solid #f4f4f4;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 2px 4px 0px rgba(171, 171, 171, 0.13);
  padding: 1rem;
  margin: 1rem 0;
  font-family: "Roboto";
  color: #6c757d;
  font-size: 16px;
}

.orng-btn {
  outline: none;
  border: none;
  font-family: "Poppins";
  border-radius: 4px;
  padding: 1rem 2rem;
  background: #f47629;
  color: white;
  font-size: 16px;
  margin: 1rem;
}

.form {
  margin-bottom: 6rem;
}

.orng-btn:hover,
.orange-btn:hover,
.read-more-btn:hover,
.client-read-btn:hover {
  cursor: pointer;
  outline: none;
}

.menu a {
  text-decoration: none;
  font-family: "Eagle Book";
  padding: 1rem;
  color: #1e2142;
}

.corner-img {
  right: 0;
  bottom: 0;
  transform: translate3d(15%, 15%, 0px);
}

.p-3-0 {
  padding: 4rem 0;
}

.orange-circle {
  height: 70px;
  width: 70px;
  background: #f47629;
  border-radius: 50%;
  margin-right: 1rem;
}

.why-us-sm {
  color: #f47629;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 26px;
  /* 162.5% */
  font-weight: bold;
}

.other-text {
  color: #7b7b7b;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.w-50p {
  width: 50%;
}

.w-80 {
  width: 80vw;
}

.w-80p {
  width: 80%;
}

.blue-color-main {
  color: #1e2142;
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.w-70p {
  width: 70%;
}

.w-95p {
  width: 95%;
}

.orng-btn {
  outline: none;
  border: none;
  font-family: "Poppins";
  border-radius: 4px;
  padding: 1rem 4rem;
  background: #f47629;
  color: white;
  font-size: 16px;
  margin: 1rem;
  width: fit-content;
  margin: auto;
}

.list-style-none {
  list-style: none;
}

.fz-24px {
  font-size: 24px;
}

.green-arrow {
  color: #26d726;
}